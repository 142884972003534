import auth from "./api.auth";
import company from "./api.company";
import contact from "./api.contact";
import helpers from "./api.helpers";
import user from "./api.user";
import project from "./api.project";
import satisfaction from "./api.satisfaction";
import wp from "./wp";

const api = {
  auth,
  company,
  contact,
  helpers,
  project,
  satisfaction,
  user,
  wp,
};

export default api;
