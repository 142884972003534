import axios from "axios";
import { isEmpty } from "lodash";
import { API_URL } from "utils/constants";
import LocalStorage from "utils/localStorage";

const APIbase = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json, text/plain", // text/plain was added because some endpoints don't work without.
  },
});

APIbase.interceptors.request.use(
  (config) => {
    const session = LocalStorage.session.get();

    if (!isEmpty(session) && session.access_token) {
      config.headers["Authorization"] = `Bearer ${session.access_token}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

export default APIbase;
