import styled, { css } from "styled-components";

const Loading = () => (
  <Center>
    <LoadingStyled />
  </Center>
);

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LoadingStyled = styled.span(
  ({ theme }) => css`
    display: inline-block;
    width: 48px;
    height: 48px;
    margin: ${theme.spacing.md} auto;

    :after {
      content: " ";
      display: block;
      width: 32px;
      height: 32px;
      margin: 8px;
      border-radius: 50%;
      border: 4px solid #fff;
      border-color: ${theme.colors.primary} transparent ${theme.colors.primary}
        transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);

export default Loading;
