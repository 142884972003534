import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const firstContactAsyncThunk = asyncThunk(
  "contact/firstContact/post",
  api.contact.firstContact
);

export const listContactAsyncThunk = asyncThunk(
  "contact/listContact/list",
  api.contact.listContact
);
