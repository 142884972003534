import QueryString from "qs";
import APIbase from "./api.baseAPI";
import {
  FirstContactRequest,
  FirstContactResponse,
  ListContactRequest,
  ListContactResponse,
} from "./api.contact.types";

const firstContact = (data: FirstContactRequest) =>
  APIbase.post<FirstContactResponse>(
    "/server/api/empresa/savePrimeiroContato",
    data
  );

const listContact = ({
  companyID,
  page,
  pageSize,
  countResults,
}: ListContactRequest) =>
  APIbase.get<ListContactResponse | number>(
    `/server/api/empresa/${companyID}/contato${QueryString.stringify(
      { page, pageSize, countResults },
      {
        addQueryPrefix: true,
      }
    )}`
  );

const apiContact = {
  firstContact,
  listContact,
};

export default apiContact;
