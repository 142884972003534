import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  GetSatisfactionByIdRequest,
  GetSatisfactionByIdResponse,
  ListSatisfactionResponse,
  Satisfaction,
} from "core/api/api.satisfaction.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { logoutAction } from "../auth/authAction";
import {
  getSatisfactionByIdAsyncThunk,
  listSatisfactionAsyncThunk,
  updateSatisfactionAsyncThunk,
} from "./satisfactionAction";

interface ISatisfaction {
  list: {
    loading: LoadingType;
    data?: ListSatisfactionResponse[];
  };
  byId: {
    [k: string]: Partial<GetSatisfactionByIdResponse> & {
      loading: LoadingType;
    };
  };
}

const SatisfactionSlice = createSlice<
  ISatisfaction,
  SliceCaseReducers<ISatisfaction>
>({
  name: "satisfaction",
  initialState: {
    list: {
      loading: "idle",
    },
    byId: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    //SATISFACTION LIST
    builder.addCase(listSatisfactionAsyncThunk.pending.toString(), (state) => {
      state.list.loading = "loading";
    });

    builder.addCase(
      listSatisfactionAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListSatisfactionResponse[]>) => {
        return {
          ...state,
          list: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listSatisfactionAsyncThunk.rejected.toString(), (state) => {
      state.list.loading = "error";
    });

    //SATISFACTION BY ID
    builder.addCase(
      getSatisfactionByIdAsyncThunk.pending.toString(),
      (state, action: ActionWithArg<undefined, GetSatisfactionByIdRequest>) => {
        if (!action.meta.arg?.id) return;

        state.byId[action.meta.arg!.id] = {
          loading: "loading",
        };
      }
    );

    builder.addCase(
      getSatisfactionByIdAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListSatisfactionResponse,
          GetSatisfactionByIdResponse
        >
      ) => {
        if (!action.meta.arg?.id) return;

        state.byId[action.meta.arg!.id] = {
          loading: "ok",
          ...action.payload,
        };
      }
    );

    builder.addCase(
      getSatisfactionByIdAsyncThunk.rejected.toString(),
      (
        state,
        action: ActionWithArg<undefined, GetSatisfactionByIdResponse>
      ) => {
        if (!action.meta.arg?.id) return;

        state.byId[action.meta.arg!.id] = {
          loading: "error",
        };
      }
    );

    builder.addCase(
      updateSatisfactionAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<Satisfaction, { id: number }>) => {
        if (state.byId[action.meta.arg?.id || ""]) {
          state.byId[action.meta.arg!.id] = {
            ...action.payload,
            loading: "ok",
          };
        }
      }
    );

    // Logout
    builder.addCase(logoutAction.toString(), (state) => {
      state.list = { loading: "idle" };
      state.byId = {};
    });
  },
});

export default SatisfactionSlice;
