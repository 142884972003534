import APIbaseWP from "./api.wp.baseAPI";
import { GetInternalPageResponse } from "./api.wp.home.types";
import { SiteResponse } from "./api.wp.types";

export interface GetPageRequest {
  slug: string;
}

const getInternalPage = ({ slug }: GetPageRequest) =>
  APIbaseWP.get<SiteResponse<GetInternalPageResponse>>(`/v1/pages/${slug}`);

const apiInternalPage = {
  getInternalPage,
};

export default apiInternalPage;
