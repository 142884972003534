import QueryString from "qs";
import APIbase from "./api.baseAPI";
import {
  GetUserResponse,
  ListUserByCompanyRequest,
  ListUserByCompanyResponse,
  ListUserExternalByCompanyResponse,
  ListUserExternalByCompanyRequest,
  CreateUserExternalRequest,
  DeleteUserExternalRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UnlinkUserExternalRequest,
  UnlinkUserExternalResponse,
  ListUserRequest,
  ListUserResponse,
} from "./api.user.types";

const getUser = () => APIbase.get<GetUserResponse>(`/server/api/user/info`);

const listUser = ({ ativo }: ListUserRequest) =>
  APIbase.get<ListUserResponse>(
    `/server/api/user?${QueryString.stringify({ ativo })}`
  );

const listUserByCompany = ({ companyID }: ListUserByCompanyRequest) =>
  APIbase.get<ListUserByCompanyResponse>(
    `/server/api/user/findByEmpresa/${companyID}`
  );

const listUserExternalByCompany = ({
  companyID,
}: ListUserExternalByCompanyRequest) =>
  APIbase.get<ListUserExternalByCompanyResponse>(
    `/server/api/userExternal/byEmpresa/${companyID}`
  );

const createUserExternal = (data: CreateUserExternalRequest) =>
  APIbase.post(`/server/api/user/crudExternalUser`, data);

const deleteUserExternal = ({ companyID, userID }: DeleteUserExternalRequest) =>
  APIbase.post(
    `/server/api/userExternal/removeExternalContact/${companyID}`,
    userID,
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8", // This EP just work with that.
      },
    }
  );

const resetPassword = (data: ResetPasswordRequest) =>
  APIbase.post<ResetPasswordResponse>(
    `/server/api/user/solicitarResetSenhas`,
    data
  );

const unlinkUserExternal = ({ companyID, userID }: UnlinkUserExternalRequest) =>
  APIbase.post<UnlinkUserExternalResponse>(
    `/server/api/userExternal/unlinkExternalContact/${companyID}`,
    userID
  );

const apiUser = {
  getUser,
  listUser,
  listUserByCompany,
  listUserExternalByCompany,
  createUserExternal,
  deleteUserExternal,
  resetPassword,
  unlinkUserExternal,
};

export default apiUser;
