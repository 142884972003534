import { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import history from "core/history";
import routes from "core/routes";
import { RejectWithValue } from "core/utils/asyncThunk";
import { Dispatch } from "react";

const Unauthorized = (_: any) => (next: Dispatch<AnyAction>) => (
  action: PayloadAction<RejectWithValue>
) => {
  if (action.type.endsWith("rejected") && action.payload?.status === 401) {
    if (history.location?.pathname?.includes(routes.dashboard.self)) {
      history.push(routes.login);
    }
  }

  return next(action);
};

export default Unauthorized;
