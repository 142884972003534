import APIbase from "./api.baseAPI";
import {
  ListCity,
  ListCNAEDivision,
  ListCNAEByParentIDResponse,
  ListCNAEByParentIDRequest,
  ListCompanyClassification,
  ListCountry,
  ListProduction,
  ListUFResponse,
  ListRelationshipResponse,
  ListProjetoTiposResponse,
  ListStatusProgressoResponse,
  ListNCMResponse,
  PostFileRequest,
  PostFileResponse,
} from "./api.helpers.types";

const listUf = () => APIbase.get<ListUFResponse>("/server/api/domain/uf");

const listProduction = () =>
  APIbase.get<ListProduction>("/server/api/domain/cadeiaProdutiva");

const listCountry = () =>
  APIbase.get<ListCountry>("/server/api/domain/pais/nome/asc");

const listCity = () =>
  APIbase.get<ListCity>("/server/api/domain/municipio/nome/asc");

const listCompanyClassification = () =>
  APIbase.get<ListCompanyClassification>("/server/api/domain/naturezaJuridica");

const listCNAEDivision = () =>
  APIbase.get<ListCNAEDivision>("/server/api/cnae");

const listCNAEByParentID = ({ ids: data }: ListCNAEByParentIDRequest) =>
  APIbase.post<ListCNAEByParentIDResponse>(
    "/server/api/cnae/findByParentId",
    data
  );

const listRelationship = () =>
  APIbase.get<ListRelationshipResponse>("/server/api/tipos/relacionamento");

const listProjetoTipos = () =>
  APIbase.get<ListProjetoTiposResponse>(`/server/api/projeto/tipos`);

const listStatusProgresso = () =>
  APIbase.get<ListStatusProgressoResponse>(
    `/server/api/domain/statusProgresso`
  );

const listNCM = () => APIbase.get<ListNCMResponse>(`/server/api/ncm`);

const listImovel = () => APIbase.get<string[]>("/server/api/tipos/imovel");

const listTerrenoCondicao = () =>
  APIbase.get<string[]>("/server/api/tipos/terreno");

const listGalpaoCondicao = () =>
  APIbase.get<string[]>("/server/api/tipos/galpao");

const listPrevisaoInstalacao = () =>
  APIbase.get<string[]>("/server/api/tipos/instalacao");

const listPorto = () => APIbase.get<string[]>("/server/api/tipos/porto");

const listRodovia = () => APIbase.get<string[]>("/server/api/tipos/rodovia");

const postFile = (data: PostFileRequest) =>
  APIbase.post<PostFileResponse>("/server/api/anexo", data.file, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const apiHelpers = {
  listUf,
  listProduction,
  listCountry,
  listCity,
  listCompanyClassification,
  listCNAEDivision,
  listCNAEByParentID,
  listRelationship,
  listProjetoTipos,
  listStatusProgresso,
  listNCM,
  listImovel,
  listTerrenoCondicao,
  listGalpaoCondicao,
  listPrevisaoInstalacao,
  listPorto,
  listRodovia,
  postFile,
};

export default apiHelpers;
