import QueryString from "qs";
import APIbase from "./api.baseAPI";
import {
  GetProjectRequest,
  GetProjectResponse,
  ListProjectRequest,
  ListProjectResponse,
  GetAcompanhamentoAnualRequest,
  GetAcompanhamentoAnualResponse,
  PutProjectXRequest,
  PutProjectXResponse,
  GetPleitoRequest,
  GetPleitoResponse,
  GetLocationRequest,
  GetLocationResponse,
  GetFinanceRequest,
  GetFinanceResponse,
  GetScheduleRequest,
  GetScheduleResponse,
  ListProductResponse,
  ListProductRequest,
  ListInfrastructureRequest,
  ListInfrastructureResponse,
  GetAmbienceRequest,
  ListAmbienceResponse,
  PostProjectRequest,
  ListLocationByProjectRequest,
  ListLocationByProjectResponse,
  DeleteJobByIdRequest,
  PostProductSheetRequest,
  PostProductSheetResponse,
  GetSheetExampleRequest,
  PostMainInputsSheetRequest,
  PostMainInputsResponse,
  PostPreFormFileInfraestruturaRequest,
  Infrastructure,
  PostIceSendFormRequest,
  PutAcompanhamentoAnualRequest,
  ListAcompanhamentoAnualAnteriores,
  ListAcompanhamentoAnualAnterioresResponse,
} from "./api.project.types";

const getAcompanhamentoAnual = (data: GetAcompanhamentoAnualRequest) =>
  APIbase.get<GetAcompanhamentoAnualResponse>(
    `/server/api/projeto/acompanhamentoAnual${QueryString.stringify(data, {
      addQueryPrefix: true,
    })}`
  );

const putAcompanhamentoAnual = (data: PutAcompanhamentoAnualRequest) =>
  APIbase.post<unknown>("/server/api/projeto/acompanhamentoAnual", data);

const listAcompanhamentoAnualAnteriores = (
  data: ListAcompanhamentoAnualAnteriores
) =>
  APIbase.get<ListAcompanhamentoAnualAnterioresResponse[]>(
    `/server/api/projeto/acompanhamentoAnual/anosanteriores${QueryString.stringify(
      data,
      { addQueryPrefix: true }
    )}`
  );

const getProject = ({ projectID }: GetProjectRequest) =>
  APIbase.get<GetProjectResponse>(`/server/api/projeto/${projectID}/projetoTO`);

const getProjectX = ({ projectID }: GetProjectRequest) =>
  APIbase.get<GetProjectResponse>(`/server/api/projeto/${projectID}`);

const getPleito = ({ projectID }: GetPleitoRequest) =>
  APIbase.get<GetPleitoResponse>(`/server/api/projeto/${projectID}/pleito`);

const getLocation = ({ projectID }: GetLocationRequest) =>
  APIbase.get<GetLocationResponse>(
    `/server/api/projeto/${projectID}/localizacao`
  );

const getFinance = ({ projectID }: GetFinanceRequest) =>
  APIbase.get<GetFinanceResponse>(
    `/server/api/projeto/${projectID}/financeiro`
  );

const getSchedule = ({ projectID }: GetScheduleRequest) =>
  APIbase.get<GetScheduleResponse>(
    `/server/api/projeto/${projectID}/cronograma`
  );

const getInfrastructure = ({ projectID }: ListInfrastructureRequest) =>
  APIbase.get<ListInfrastructureResponse>(
    `/server/api/projeto/${projectID}/infraestrutura`
  );

const getAmbience = ({ projectID }: GetAmbienceRequest) =>
  APIbase.get<ListAmbienceResponse>(
    `/server/api/projeto/${projectID}/meioAmbiente`
  );

const listProduct = ({ projectID }: ListProductRequest) =>
  APIbase.get<ListProductResponse>(
    `/server/api/projeto/${projectID}/insumoProduto`
  );

const postProject = (data: PostProjectRequest) =>
  APIbase.post<number>("/server/api/projeto/full", data);

const putProjectX = (props: PutProjectXRequest) => {
  console.log(props);
  return APIbase.put<PutProjectXResponse>(
    `/server/api/projeto/${props.id}`,
    props
  );
};

const listProject = ({ empresasId, countResults }: ListProjectRequest) =>
  APIbase.get<ListProjectResponse>(
    `/server/api/projeto${QueryString.stringify(
      { countResults, empresasId },
      {
        addQueryPrefix: true,
        arrayFormat: "repeat",
      }
    )}`
  );

const listLocationByProject = ({
  projectID,
  keyword,
}: ListLocationByProjectRequest) =>
  APIbase.get<ListLocationByProjectResponse>(
    `/server/api/projeto/${projectID}/localizacao/findLocalizacoes/${keyword}`
  );

const deleteJobById = ({ projectID, jobs }: DeleteJobByIdRequest) =>
  APIbase.post<unknown>(
    `/server/api/projeto/${projectID}/emprego/removeEmprego`,
    jobs
  );

const postProductSheet = ({ projectID, data }: PostProductSheetRequest) =>
  APIbase.post<PostProductSheetResponse[]>(
    `/server/api/projeto/${projectID}/insumoProduto/uploadProdutos`,
    data
  );

const postMainInputsSheet = ({ projectID, data }: PostMainInputsSheetRequest) =>
  APIbase.post<PostMainInputsResponse[]>(
    `/server/api/projeto/${projectID}/insumoProduto/uploadInsumos`,
    data
  );

const getProductSheetExample = ({ projectID }: GetSheetExampleRequest) =>
  APIbase.get<Blob>(
    `/server/api/projeto/${projectID}/insumoProduto/fetchProdutosTemplate`,
    {
      responseType: "blob",
      headers: {
        Accept: "application/xls",
      },
    }
  );

const getMainInputsSheetExample = ({ projectID }: GetSheetExampleRequest) =>
  APIbase.get<Blob>(
    `/server/api/projeto/${projectID}/insumoProduto/fetchInsumosTemplate`,
    {
      responseType: "blob",
      headers: {
        Accept: "application/xls",
      },
    }
  );

const postPreFormFileInfraestrutura = ({
  projectID,
  file,
}: PostPreFormFileInfraestruturaRequest) =>
  APIbase.post<Infrastructure>(
    `/server/api/projeto/${projectID}/infraestrutura/preFormFile`,
    file,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

const postIceSendForm = ({ projectID }: PostIceSendFormRequest) =>
  APIbase.post<Infrastructure>(`/server/api/ice/${projectID}/send`, {
    projectID,
  });

const apiProject = {
  getProject,
  getProjectX,
  getPleito,
  getLocation,
  getFinance,
  getSchedule,
  getInfrastructure,
  getAmbience,
  putProjectX,
  postProject,
  listProject,
  listProduct,
  listLocationByProject,
  getAcompanhamentoAnual,
  putAcompanhamentoAnual,
  listAcompanhamentoAnualAnteriores,
  deleteJobById,
  postProductSheet,
  postMainInputsSheet,
  getProductSheetExample,
  getMainInputsSheetExample,
  postPreFormFileInfraestrutura,
  postIceSendForm,
};

export default apiProject;
