import GlobalStyle from "core/styles";
import { ThemeProvider } from "styled-components";
import ManagerActions from "core/providers/ManagerActions";
import { theme } from "core/theme";
import { Provider } from "react-redux";
import store from "core/store";
import React, { Suspense } from "react";
import LoadingFull from "components/Loading/LoadingFull";
import FeedbackProvider from "core/providers/FeedbackProvider";

const AppLoaderLazy = React.lazy(() => import("./AppLoader"));

const App = () => (
  <ThemeProvider theme={theme}>
    <Suspense fallback={<LoadingFull />}>
      <Provider store={store}>
        <ManagerActions>
          <FeedbackProvider>
            <GlobalStyle />
            <AppLoaderLazy />
          </FeedbackProvider>
        </ManagerActions>
      </Provider>
    </Suspense>
  </ThemeProvider>
);

export default App;
