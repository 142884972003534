interface ThemeSizing<T = string> {
  sm: T;
  md: T;
  lg: T;
}

interface ThemeSpaceSizing<T = string> extends ThemeSizing<T> {
  xxs: T;
  xs: T;
  xl: T;
  xxl: T;
}

export type SpacingSize = keyof ThemeSpaceSizing;

interface Colors {
  primary: string;
  primaryText: string;
  black: string;
  backgroundDark: string;
  backgroundDarkness: string;
  white: string;
  neutral100: string;
  neutral200: string;
  neutral300: string;
  neutral400: string;
  neutral500: string;
  neutral600: string;
  feedbackSuccess: string;
  feedbackWarning: string;
  feedbackInfo: string;
  feedbackDanger: string;
}

export type Color = keyof Colors;

interface ThemeFontSize<T = string> extends ThemeSizing<T> {
  xs: T;
  xl: T;
  xxl: T;
  huge: T;
}

export type FontSize = keyof ThemeFontSize;
export interface Theme {
  colors: Colors;
  spacing: ThemeSpaceSizing;
  font: {
    size: ThemeFontSize;
  };
  border: {
    radius: ThemeSizing;
  };
}

export const theme: Theme = {
  colors: {
    primary: "#D5191A",
    primaryText: "#fff",
    backgroundDark: "#181E2A",
    backgroundDarkness: "#0F131B",
    neutral100: "#F8F9FA",
    neutral200: "#DDE2E5",
    neutral300: "#ACB5BD",
    neutral400: "#999999",
    neutral500: "#495057",
    neutral600: "#333333",
    black: "#212429",
    white: "#ffffff",
    feedbackSuccess: "#388F46",
    feedbackWarning: "#ffc107",
    feedbackInfo: "#4263EB",
    feedbackDanger: "#D84910",
  },
  spacing: {
    xxs: "4px",
    xs: "8px",
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "48px",
    xxl: "64px",
  },
  font: {
    size: {
      xs: "12px",
      sm: "16px",
      md: "18px",
      lg: "24px",
      xl: "32px",
      xxl: "40px",
      huge: "64px",
    },
  },
  border: {
    radius: {
      sm: "4px",
      md: "8px",
      lg: "10px",
    },
  },
};
