import { updateMenuAction } from "core/store/slices/site/home/siteMenuActions";
import { AnyAction, Dispatch, MiddlewareAPI } from "redux";

const Menu = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  if (action?.payload?.$menus) {
    store.dispatch(
      updateMenuAction({
        lang: action.payload.data.__response_lang,
        menu: action.payload.$menus,
      })
    );
  }

  return next(action);
};

export default Menu;
