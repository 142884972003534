import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { SiteLang, SiteMenu } from "core/api/wp/api.wp.types";
import {
  SiteMenuMultiLang,
  updateMenuAction,
  updateSiteLangAction,
} from "./siteMenuActions";

interface IHomeState {
  menu?: {
    [k: string]: SiteMenu;
  };
  siteLang?: SiteLang;
}

const SiteMenuSlice = createSlice<IHomeState, SliceCaseReducers<IHomeState>>({
  name: "siteMenu",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase<string, PayloadAction<SiteMenuMultiLang>>(
      updateMenuAction.toString(),
      (state, action) => {
        if (!state.menu) {
          state.menu! = {};
        }
        state.menu[action.payload.lang] = action.payload.menu;
      }
    );
    builder.addCase<string, PayloadAction<SiteLang>>(
      updateSiteLangAction.toString(),
      (state, action) => {
        state.siteLang = action.payload;
      }
    );
  },
});

export default SiteMenuSlice;
