import store from "core/store";
import { updateSiteLangAction } from "core/store/slices/site/home/siteMenuActions";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const DEFAULT_LANG = "pt";

i18next
  .use(HttpApi)
  .use(initReactI18next)
  .init(
    {
      lng: DEFAULT_LANG,
      debug: false,
      defaultNS: "translation",
      ns: ["translation"],
      fallbackLng: ["pt", "en"],
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    },
    function (err) {
      if (err) {
        console.error(err);
      } else {
        console.log("::i18n started::");
        store.dispatch(updateSiteLangAction(DEFAULT_LANG));
      }
    }
  );

export default i18next;
