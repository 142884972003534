import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const getProjectAsyncThunk = asyncThunk(
  "project/get",
  api.project.getProject,
  {
    condition: (args, store) =>
      !store.getState().project.projects.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getProjectXAsyncThunk = asyncThunk(
  "project/getX",
  api.project.getProjectX,
  {
    condition: (args, store) =>
      !store.getState().project.projects.dataX[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getPleitoAsyncThunk = asyncThunk(
  "project/pleito/get",
  api.project.getPleito,
  {
    condition: (args, store) =>
      !store.getState().project.pleito.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getLocationAsyncThunk = asyncThunk(
  "project/location/get",
  api.project.getLocation,
  {
    condition: (args, store) =>
      !store.getState().project.location.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getFinanceAsyncThunk = asyncThunk(
  "project/finance/get",
  api.project.getFinance,
  {
    condition: (args, store) =>
      !store.getState().project.finance.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getScheduleAsyncThunk = asyncThunk(
  "project/schedule/get",
  api.project.getSchedule,
  {
    condition: (args, store) =>
      !store.getState().project.schedule.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const listProductAsyncThunk = asyncThunk(
  "project/product/list",
  api.project.listProduct,
  {
    condition: (args, store) =>
      !store.getState().project.products.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getInfrastructureAsyncThunk = asyncThunk(
  "project/infrastructure/get",
  api.project.getInfrastructure,
  {
    condition: (args, store) =>
      !store.getState().project.infrastructure.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const getAmbienceAsyncThunk = asyncThunk(
  "project/ambience/get",
  api.project.getAmbience,
  {
    condition: (args, store) =>
      !store.getState().project.ambience.data[args?.projectID] ||
      !!args.thunkOptions?.isForce,
  }
);

export const postProjectAsyncThunk = asyncThunk(
  "project/update/post",
  api.project.postProject
);

export const listProjectAsyncThunk = asyncThunk(
  "project/list",
  api.project.listProject
);

export const getAcompanhamentoAnualAsyncThunk = asyncThunk(
  "project/acompanhamentoAnual/get",
  api.project.getAcompanhamentoAnual
);

export const putAcompanhamentoAnualAsyncThunk = asyncThunk(
  "project/acompanhamentoAnual/put",
  api.project.putAcompanhamentoAnual
);

export const listAcompanhamentoAnualAnterioresAsyncThunk = asyncThunk(
  "project/acompanhamentoAnualAnteriores/get",
  api.project.listAcompanhamentoAnualAnteriores
);

export const listLocationByProjectAsyncThunk = asyncThunk(
  "project/location/list",
  api.project.listLocationByProject
);

export const deleteJobByIdAsyncThunk = asyncThunk(
  "project/jobById/delete",
  api.project.deleteJobById
);

export const postProductSheetAsyncThunk = asyncThunk(
  "project/productSheet/post",
  api.project.postProductSheet
);

export const postMainInputsSheetAsyncThunk = asyncThunk(
  "project/mainInputsSheet/post",
  api.project.postMainInputsSheet
);

export const postPreFormFileInfraestruturaAsyncThunk = asyncThunk(
  "project/preFormFileInfraestrutura/post",
  api.project.postPreFormFileInfraestrutura
);

export const postIceSendFormAsyncThunk = asyncThunk(
  "project/postIceSendForm/post",
  api.project.postIceSendForm
);
