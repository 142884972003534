enum LocalStorageKey {
  SESSION = "session",
}

interface Session {
  access_token?: string;
  expires_in?: number;
}

const readLocalStorage = (key: LocalStorageKey) =>
  JSON.parse(localStorage.getItem(key) || "{}");

const writeLocalStorage = (
  key: LocalStorageKey,
  data: Object | string | boolean | number
) => localStorage.setItem(key, JSON.stringify(data));

const LocalStorage = {
  session: {
    get: (): Partial<Session> => readLocalStorage(LocalStorageKey.SESSION),
    set: (data: Partial<Session>) => {
      const prev = readLocalStorage(LocalStorageKey.SESSION);
      writeLocalStorage(LocalStorageKey.SESSION, { ...prev, ...data });
    },
    del: () => localStorage.removeItem(LocalStorageKey.SESSION),
  },
};

export default LocalStorage;
