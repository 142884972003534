import { Color } from "core/theme";
import { ptBR, enUS } from "date-fns/locale";

export const API_URL = process.env.REACT_APP_API_URL;

export const API_WP_URL = process.env.REACT_APP_API_WP_URL;

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

export const DEFAULT_USER = process.env.REACT_APP_DEFAULT_USER;

export const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;

export const BRASIL_ID = 2;

export const MINAS_GERAIS_ID = 35;

export const INDI_MAPS =
  "https://indimg.maps.arcgis.com/apps/webappviewer/index.html?id=d73d30f9f3664b8696e0349ad23baa06";

export const FACEBOOK = "https://www.facebook.com/indi.minasgerais";

export const YOUTUBE =
  "https://www.youtube.com/c/INDIAg%C3%AAnciadePromo%C3%A7%C3%A3odeInvestimentosdeMG";

export const LINKEDIN = "https://www.linkedin.com/company/indi.minasgerais";

export const INSTAGRAM = "https://www.instagram.com/indi.minasgerais/";

export const SPOTIFY =
  "https://open.spotify.com/show/0DqS5Opg2TvVFfIgpRXUMN?si=uEcuXi9rTyi1-9d0l-5sBA&utm_source=whatsapp";

export const QUERO_INVESTIR =
  "https://plataformamineiradoinvestidor.com/projeto/apresentacao";

export const QUERO_IMPORTAR = "https://www.indi.mg.gov.br/quero-exportar/";

export const APOIO_MUNICIPIOS =
  "https://www.indi.mg.gov.br/apoio-aos-municipios/";

export const SERVICOS_PRESTADOS_INDI =
  "https://www.indi.mg.gov.br/o-indi/servicos-prestados-pelo-indi/";

export const TRATAMENTOS_TRIBUTARIOS =
  "https://www.indi.mg.gov.br/tratamento-tributario-setorial/";

export const CREDITOS_FINANCIAMENTOS =
  "https://www.indi.mg.gov.br/credito-e-financiamento/";

export const FAPEMIG = "https://www.indi.mg.gov.br/fapemig/";

export const INCENTIVOS_BENEFICOS =
  "https://www.indi.mg.gov.br/incentivos-e-beneficios-fiscais-da-regiao-da-sudene/";

export const PARQUES_TECNOLOGICOS =
  "https://www.indi.mg.gov.br/parques-tecnologicos/";

export const SHEET_EXAMPLE =
  "/server/api/projeto/:projectSlug/insumoProduto/fetchProdutosTemplate";

export const AUTH_REQUEST_DATA = {
  environment: process.env.REACT_APP_AUTH_ENVIRONMENT,
  grant_type: process.env.REACT_APP_AUTH_GRANT_TYPE,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
};

export const LOCALES: { [k: string]: Locale } = {
  pt: ptBR,
  en: enUS,
};

interface ProjetoSituacao {
  id: string;
  idValue: number;
  descricao: string;
  color: Color;
}

export const SITUACAO_PROJETO: { [propName: string]: ProjetoSituacao } = {
  ATIVO: {
    id: "ATIVO",
    idValue: 1,
    descricao: "Ativo",
    color: "feedbackSuccess",
  },
  CANCELADO: {
    id: "CANCELADO",
    idValue: 2,
    descricao: "Cancelado",
    color: "feedbackDanger",
  },
  PENDENTE_ACEITE: {
    id: "PENDENTE_ACEITE",
    idValue: 3,
    descricao: "Pendente de Aceite",
    color: "feedbackWarning",
  },
  PEDENTE_VALIDACAO: {
    id: "PEDENTE_VALIDACAO",
    idValue: 4,
    descricao: "Pendente de Validação",
    color: "feedbackWarning",
  },
  SUSPENSO: {
    id: "SUSPENSO",
    idValue: 5,
    descricao: "Suspenso",
    color: "feedbackDanger",
  },
  CONCLUIDO: {
    id: "CONCLUIDO",
    idValue: 6,
    descricao: "Concluido",
    color: "feedbackSuccess",
  },
};

export const situacoesProjetoArr = Object.values(SITUACAO_PROJETO);

export const ESTAGIO_PROJETO = {
  FORMALIZADA: {
    id: "FORMALIZADA",
    idValue: 1,
    descricao: "Decisão Formalizada",
  },
  IMPLANTACAO_INICIADO: {
    id: "IMPLANTACAO_INICIADO",
    idValue: 2,
    descricao: "Implantação Iniciada",
  },
  INICIO_PROJETO: {
    id: "INICIO_PROJETO",
    idValue: 3,
    descricao: "Início de Projeto",
  },
  OPERACAO_INICIADA: {
    id: "OPERACAO_INICIADA",
    idValue: 4,
    descricao: "Operação Iniciada",
  },
  PROJETO_PROMISSOR: {
    id: "PROJETO_PROMISSOR",
    idValue: 5,
    descricao: "Projeto Promissor",
  },
  COMPROMISSO_CUMPRIDO: {
    id: "COMPROMISSO_CUMPRIDO",
    idValue: 6,
    descricao: "Compromisso Cumprido",
  },
};

export type EstagioProjetoType = keyof typeof ESTAGIO_PROJETO;

export const SITUACAO_RELATORIO_ACOMPANHAMENTO = {
  NAO_SOLICITADO: {
    id: "NAO_SOLICITADO",
    descricao: "Não Solicitado pelo INDI",
  },
  NAO_RESPONDIDO: {
    id: "NAO_RESPONDIDO",
    descricao: "Não Respondido pela Empresa",
  },
  PENDENTE_VALIDACAO: {
    id: "PENDENTE_VALIDACAO",
    descricao: "Pendente de Validação",
  },
  VALIDADO: {
    id: "VALIDADO",
    descricao: "Validado pelo Analista",
  },
};

export type RelatorioAcompanhamentoType = keyof typeof SITUACAO_RELATORIO_ACOMPANHAMENTO;
