import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const listUFAsyncThunk = asyncThunk(
  "helpers/uf/list",
  api.helpers.listUf,
  {
    condition: (_, state) => !state.getState().helpers.uf.data.length,
  }
);

export const listProductionAsyncThunk = asyncThunk(
  "helpers/production/list",
  api.helpers.listProduction,
  {
    condition: (args, state) =>
      !state.getState().helpers.production.data.length ||
      !!args.thunkOptions?.isForce,
  }
);

export const listCountryAsyncThunk = asyncThunk(
  "helpers/country/list",
  api.helpers.listCountry,
  {
    condition: (_, state) => !state.getState().helpers.country.data.length,
  }
);

export const listCityAsyncThunk = asyncThunk(
  "helpers/city/list",
  api.helpers.listCity,
  {
    condition: (_, state) => !state.getState().helpers.city.data.length,
  }
);

export const listCompanyClassificationAsyncThunk = asyncThunk(
  "helpers/companyClassification/list",
  api.helpers.listCompanyClassification,
  {
    condition: (_, state) =>
      !state.getState().helpers.companyClassification.data.length,
  }
);

export const listCNAEDivisionAsyncThunk = asyncThunk(
  "helpers/CNAEDivision/list",
  api.helpers.listCNAEDivision,
  {
    condition: (_, state) => !state.getState().helpers.CNAEDivision.data.length,
  }
);

export const listCNAEByParentIDAsyncThunk = asyncThunk(
  "helpers/CNAEGroup/list",
  api.helpers.listCNAEByParentID,
  {
    condition: (args, state) =>
      !Object.keys(state.getState().helpers.CNAEByParentID.data).find((g) =>
        args.ids.includes(g)
      ),
  }
);

export const listRelationshipAsyncThunk = asyncThunk(
  "helpers/relationship/list",
  api.helpers.listRelationship,
  {
    condition: (_, state) =>
      !state.getState().helpers.relationship.data?.length,
  }
);

export const listProjetoTiposAsyncThunk = asyncThunk(
  "helpers/projetoTipos/list",
  api.helpers.listProjetoTipos,
  {
    condition: (args, store) =>
      store.getState().helpers.projetoTipos.loading === "idle" ||
      store.getState().helpers.projetoTipos.loading === "error" ||
      !!args.thunkOptions?.isForce,
  }
);

export const listStatusProgressoAsyncThunk = asyncThunk(
  "helpers/statusProgresso/list",
  api.helpers.listStatusProgresso,
  {
    condition: (args, store) =>
      store.getState().helpers.statusProgresso.loading === "idle" ||
      store.getState().helpers.statusProgresso.loading === "error" ||
      !!args.thunkOptions?.isForce,
  }
);

export const listNCMAsyncThunk = asyncThunk(
  "helpers/ncm/list",
  api.helpers.listNCM,
  {
    condition: (_, store) =>
      store.getState().helpers.ncm.loading === "idle" ||
      store.getState().helpers.ncm.loading === "error",
  }
);

export const listImovelAsyncThunk = asyncThunk(
  "helpers/imovel/list",
  api.helpers.listImovel,
  { condition: (_, store) => !store.getState().helpers.imovel.data.length }
);

export const listTerrenoCondicaoAsyncThunk = asyncThunk(
  "helpers/terrenoCondicao/list",
  api.helpers.listTerrenoCondicao,
  {
    condition: (_, store) =>
      !store.getState().helpers.terrenoCondicao.data.length,
  }
);

export const listGalpaoCondicaoAsyncThunk = asyncThunk(
  "helpers/galpaoCondicao/list",
  api.helpers.listGalpaoCondicao,
  {
    condition: (_, store) =>
      !store.getState().helpers.galpaoCondicao.data.length,
  }
);

export const listPrevisaoInstalacaoAsyncThunk = asyncThunk(
  "helpers/previsaoInstalacao/list",
  api.helpers.listPrevisaoInstalacao,
  {
    condition: (_, store) =>
      !store.getState().helpers.previsaoInstalacao.data.length,
  }
);

export const listPortoAsyncThunk = asyncThunk(
  "helpers/porto/list",
  api.helpers.listPorto,
  {
    condition: (_, store) => !store.getState().helpers.porto.data.length,
  }
);

export const listRodoviaAsyncThunk = asyncThunk(
  "helpers/rodovia/list",
  api.helpers.listRodovia,
  {
    condition: (_, store) => !store.getState().helpers.rodovia.data.length,
  }
);

export const postFileAsyncThunk = asyncThunk(
  "helpers/file/post",
  api.helpers.postFile
);
