import APIbase from "./api.baseAPI";
import {
  GetSatisfactionByIdRequest,
  GetSatisfactionByIdResponse,
  ListSatisfactionResponse,
  Satisfaction,
} from "./api.satisfaction.types";

const getSatisfactionById = ({ id }: GetSatisfactionByIdRequest) =>
  APIbase.get<GetSatisfactionByIdResponse>(
    `/server/api/instrumento/pesquisa/byId/${id}`
  );

const listSatisfaction = () =>
  APIbase.get<ListSatisfactionResponse[]>(
    `/server/api/instrumento/pesquisa/empresausuario`
  );

const updateSatisfaction = (data: Partial<Satisfaction>) =>
  APIbase.post<Satisfaction>("/server/api/instrumento/pesquisa", data);

const apiSatisfaction = {
  getSatisfactionById,
  listSatisfaction,
  updateSatisfaction,
};

export default apiSatisfaction;
