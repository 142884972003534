import { createAction } from "@reduxjs/toolkit";
import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const authAsyncThunk = asyncThunk("auth/user", api.auth.auth);

export const passwordRecoveryAsyncThunk = asyncThunk(
  "auth/password/recovery",
  api.auth.passwordRecovery
);

export const activateUserAsyncThunk = asyncThunk(
  "auth/user/activate",
  api.auth.activateUser
);

export const logoutAction = createAction("auth/user/logout");
