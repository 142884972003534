import styled, { css } from "styled-components";
import Loading from "./Loading";

const LoadingFull = () => (
  <Wrapper>
    <Loading />
  </Wrapper>
);
export default LoadingFull;

const Wrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `
);
