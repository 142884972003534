import api from "core/api";
import { SiteLang } from "core/api/wp/api.wp.types";
import asyncThunk from "core/utils/asyncThunk";

export const getSiteHomeAsyncThunk = asyncThunk<{ lang: SiteLang }>(
  "site/home/get",
  api.wp.home.getHome,
  {
    condition: (props, state) =>
      !state.getState().site.home.home.data[props?.lang || "pt"],
  }
);
