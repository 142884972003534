import { createAction } from "@reduxjs/toolkit";
import { SiteLang, SiteMenu } from "core/api/wp/api.wp.types";
export interface SiteMenuMultiLang {
  lang: SiteLang;
  menu: SiteMenu;
}

export const updateMenuAction = createAction(
  "menu/update",
  (data: SiteMenuMultiLang) => ({ payload: data })
);

export const updateSiteLangAction = createAction(
  "menu/updateSiteLang",
  (data: SiteLang) => ({ payload: data })
);
