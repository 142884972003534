import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  GetUserResponse,
  ListUserByCompanyResponse,
  ListUserExternalByCompanyResponse,
  ListUserResponse,
} from "core/api/api.user.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { logoutAction } from "../auth/authAction";
import {
  getUserAsyncThunk,
  listUserAsyncThunk,
  listUserByCompanyAsyncThunk,
  listUserExternalByCompanyAsyncThunk,
} from "./userAction";

interface IUser {
  info: Partial<GetUserResponse> & {
    loading: LoadingType;
  };
  byCompany: {
    loading: LoadingType;
    data: { [index: string]: ListUserByCompanyResponse };
  };
  externalByCompany: {
    loading: LoadingType;
    data: { [index: string]: ListUserExternalByCompanyResponse };
  };
  list: {
    loading: LoadingType;
    data: ListUserResponse;
  };
}

const UserSlice = createSlice<IUser, SliceCaseReducers<IUser>>({
  name: "user",
  initialState: {
    info: {
      loading: "idle",
    },
    byCompany: {
      loading: "idle",
      data: {},
    },
    externalByCompany: {
      loading: "idle",
      data: {},
    },
    list: {
      loading: "idle",
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    //UF
    builder.addCase(getUserAsyncThunk.pending.toString(), (state) => {
      state.info.loading = "loading";
    });

    builder.addCase(
      getUserAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<GetUserResponse>) => {
        return {
          ...state,
          info: {
            loading: "ok",
            ...action.payload,
          },
        };
      }
    );

    builder.addCase(getUserAsyncThunk.rejected.toString(), (state) => {
      state.info.loading = "error";
    });

    //UserByCompany
    builder.addCase(listUserByCompanyAsyncThunk.pending.toString(), (state) => {
      state.byCompany.loading = "loading";
    });

    builder.addCase(
      listUserByCompanyAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListUserByCompanyResponse,
          { companyID: string | number }
        >
      ) => {
        if (!action.meta.arg?.companyID) return;
        state.byCompany.data[action.meta.arg?.companyID] = action.payload;
        state.byCompany.loading = "ok";
      }
    );

    builder.addCase(
      listUserByCompanyAsyncThunk.rejected.toString(),
      (state) => {
        state.byCompany.loading = "error";
      }
    );

    //UserExternalByCompany
    builder.addCase(
      listUserExternalByCompanyAsyncThunk.pending.toString(),
      (state) => {
        state.externalByCompany.loading = "loading";
      }
    );

    builder.addCase(
      listUserExternalByCompanyAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListUserByCompanyResponse,
          { companyID: string | number }
        >
      ) => {
        if (!action.meta.arg?.companyID) return;
        state.externalByCompany.data[action.meta.arg?.companyID] =
          action.payload;
        state.externalByCompany.loading = "ok";
      }
    );

    builder.addCase(
      listUserExternalByCompanyAsyncThunk.rejected.toString(),
      (state) => {
        state.externalByCompany.loading = "error";
      }
    );

    // List
    builder.addCase(listUserAsyncThunk.pending.toString(), (state) => {
      state.list.loading = "loading";
    });

    builder.addCase(
      listUserAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListUserByCompanyResponse>) => {
        state.list.loading = "ok";
        state.list.data = action.payload;
      }
    );

    builder.addCase(listUserAsyncThunk.rejected.toString(), (state) => {
      state.list.loading = "error";
    });

    // Logout
    builder.addCase(logoutAction.toString(), (state) => {
      state.info = { loading: "idle" };
      state.byCompany = {
        loading: "idle",
        data: {},
      };
      state.externalByCompany = {
        loading: "idle",
        data: {},
      };
    });
  },
});

export default UserSlice;
