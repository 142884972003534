import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { ListContactResponse } from "core/api/api.contact.types";
import { ActionWithArg, LoadingType, WithPagination } from "core/utils/types";
import { listContactAsyncThunk } from "./contactAction";

interface IContact {
  loading: LoadingType;
  list: WithPagination<ListContactResponse>;
}

const ContactSlice = createSlice<IContact, SliceCaseReducers<IContact>>({
  name: "contact",
  initialState: {
    loading: "idle",
    list: {
      page: 1,
      pageSize: 10,
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      listContactAsyncThunk.pending.toString(),
      (
        state,
        action: ActionWithArg<
          ListContactResponse,
          { page: number; pageSize: number }
        >
      ) => {
        state.loading = "loading";
        state.list.page = action.meta.arg?.page || 1;
        state.list.pageSize = action.meta.arg?.pageSize || 10;
      }
    );

    builder.addCase(
      listContactAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListContactResponse>) => {
        state.loading = "ok";
        state.list.data = action.payload;
      }
    );

    builder.addCase(listContactAsyncThunk.rejected.toString(), (state) => {
      state.loading = "error";
    });
  },
});

export default ContactSlice;
