import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";
import { isEmpty } from "lodash";

export const getUserAsyncThunk = asyncThunk("user/info/get", api.user.getUser, {
  condition: (_, state) => isEmpty(state.getState().user?.info?.userPrincipal),
});

export const listUserAsyncThunk = asyncThunk("user/list", api.user.listUser, {
  condition: (args, store) =>
    ["loading", "error"].findIndex(
      (u) => store.getState().user.list.loading === u
    ) > -1 || !!args.thunkOptions?.isForce,
});

export const listUserByCompanyAsyncThunk = asyncThunk(
  "user/byCompany/list",
  api.user.listUserByCompany,
  {
    condition: (args, store) =>
      !store.getState().user.byCompany.data[args?.companyID || ""]?.length ||
      !!args.thunkOptions?.isForce,
  }
);

export const listUserExternalByCompanyAsyncThunk = asyncThunk(
  "user/external/byCompany/list",
  api.user.listUserExternalByCompany,
  {
    condition: (args, store) =>
      !store.getState().user.externalByCompany.data[args.companyID]?.length ||
      !!args.thunkOptions?.isForce,
  }
);

export const createUserExternalAsyncThunk = asyncThunk(
  "user/external/create",
  api.user.createUserExternal
);

export const deleteUserExternalAsyncThunk = asyncThunk(
  "user/external/delete",
  api.user.deleteUserExternal
);

export const resetPasswordAsyncThunk = asyncThunk(
  "user/external/resetPassword",
  api.user.resetPassword
);

export const unlinkUserExternalAsyncThunk = asyncThunk(
  "user/external/unlink",
  api.user.unlinkUserExternal
);
