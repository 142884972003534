import { include } from "named-urls";

const routes = {
  home: "/",
  login: "/login",
  logout: "/logout",
  project: "/projeto/apresentacao",
  help: "/ajuda",
  messages: "/mensagens",
  terms: "/termos",
  passwordRecovery: "/nova/senha",
  page: "/:pageSlug",

  dashboard: include("/dashboard/", {
    self: "",
    company: include("empresa", {
      self: "",
      edit: include("editar/:companySlug", {
        self: "",
        workflow: "workflow",
        unit: include("unidade/", {
          self: "",
          new: "nova",
          edit: "editar/:unitSlug",
          preview: "visualizar/:unitSlug",
        }),
        activity: include("historico-de-atividades/", {
          self: "",
          new: "nova",
          preview: "visualizar/:activitySlug",
        }),
        contact: include("contatos/", {
          self: "",
          new: "novo",
          edit: "editar/:contactSlug",
          preview: "visualizar/:contactSlug",
        }),
        linkedProcess: "processos-vinculados/:unitSlug?",
      }),
    }),
    user: "usuarios",
    project: include("projetos/", {
      self: "",
      edit: include("editar/:projectSlug", {
        self: "",
        unified_form: include("formulario-unico/", {
          self: "",
          newMinasProducts: "produtos-adq-e-comercializados-por-minas/novo",
          newOtherStatesProducts:
            "produtos-adq-e-comercializados-por-outros-estados/novo",
          importedProducts: "produtos-importados-para-comercializacao/novo",
          mainInputs: "principais-insumos/novo",
          minasCompetitors: "principais-concorrentes-em-minas-gerais/novo",
          plannedInvestment: "investimento-previsto/novo",
          revenueInfo: "faturamento-anterior/novo",
          revenueExpected: "faturamento-previsto/novo",
          jobInfoPermanent: "dados-dos-empregos-permanentes/novo",
          jobInfoTemporary: "dados-dos-empregos-temporarios/novo",
        }),
        workflow: "workflow",
        monitoring: "acompanhamento-anual",
      }),
    }),
    summary: "resumo",
    satisfaction: include("pesquisa-de-satisfacao/", {
      self: "",
      details: "detalhe/:satisfactionID",
      edit: "editar/:satisfactionID",
    }),
  }),
};

export default routes;
