import useReduxDispatch from "core/hooks/useReduxDispatch";
import useReduxSelector from "core/hooks/useReduxSelector";
import { feedbackAction } from "core/store/slices/feedback/feedbackAction";
import { FC } from "core/utils/types";
import { createContext, useCallback, useEffect } from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { css } from "styled-components";

export enum FeedbackType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export interface FeedbackData {
  type?: FeedbackType;
  message: string | undefined;
  toastProps?: ToastOptions;
}

type FeedbackMessage = FeedbackData;

interface FeedbackProviderInterface {
  feedback(data: FeedbackMessage): void;
}

export const FeedbackContext = createContext<FeedbackProviderInterface>(
  {} as FeedbackProviderInterface
);

const FeedbackProvider: FC = ({ children }) => {
  const dispatch = useReduxDispatch();
  const { type, message, toastProps } = useReduxSelector(
    (state) => state.feedback?.data
  );

  const handleFeedback = useCallback(
    ({ message, type = FeedbackType.INFO, toastProps }: FeedbackMessage) => {
      dispatch(feedbackAction({ type, message, toastProps }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (message) {
      toast(message, {
        type,
        onClose: () => {
          dispatch(
            feedbackAction({ type: FeedbackType.INFO, message: undefined })
          );
        },
        ...(toastProps || {}),
      });
    }
  }, [dispatch, type, message, handleFeedback]);

  return (
    <FeedbackContext.Provider
      value={{
        feedback: handleFeedback,
      }}
    >
      <ToastContainerStyled />
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;

const ToastContainerStyled = styled(ToastContainer)(
  ({ theme }) => css`
    .Toastify {
      &__toast {
        &--info {
          background-color: ${theme.colors.feedbackInfo};
        }
        &--success {
          background-color: ${theme.colors.feedbackSuccess};
        }
        &--warning {
          background-color: ${theme.colors.feedbackWarning};
        }
        &--error {
          background-color: ${theme.colors.feedbackDanger};
        }
      }
    }
  `
);
