import { combineReducers } from "@reduxjs/toolkit";

import homeSlice from "./home/homeSlice";
import pagesSlice from "./home/pagesSlice";
import siteMenuSlice from "./home/siteMenu";

const siteReducer = combineReducers({
  home: homeSlice.reducer,
  pages: pagesSlice.reducer,
  menu: siteMenuSlice.reducer,
});

export default siteReducer;
