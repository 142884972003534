import axios from "axios";
import store from "core/store";
import { API_WP_URL } from "utils/constants";

const APIbaseWP = axios.create({
  baseURL: API_WP_URL,
  headers: {
    Accept: "application/json",
  },
});

APIbaseWP.interceptors.request.use(
  (config) => {
    const { menu: menuState } = store.getState().site;

    if (
      !menuState.menu ||
      (menuState.siteLang && !menuState.menu[menuState.siteLang])
    ) {
      config.params = {
        withMenus: 1,
      };
    }

    if (menuState.siteLang && menuState.siteLang !== "pt") {
      config.baseURL = config.baseURL?.replace(
        "/wp-json/",
        `/${menuState.siteLang}/wp-json/`
      );
    }

    return config;
  },
  (err) => Promise.reject(err)
);

APIbaseWP.interceptors.response.use(
  (response) => {
    response.data.data.__response_lang =
      store.getState().site.menu.siteLang || "pt";

    return response;
  },
  (error) => error
);

export default APIbaseWP;
