import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const getSatisfactionByIdAsyncThunk = asyncThunk(
  "satisfaction/survey/byId/get",
  api.satisfaction.getSatisfactionById,
  { condition: (req, state) => !state.getState().satisfaction.byId[req?.id] }
);

export const listSatisfactionAsyncThunk = asyncThunk(
  "satisfaction/survey/list",
  api.satisfaction.listSatisfaction,
  { condition: (_, state) => !state.getState().satisfaction.list?.data?.length }
);

export const updateSatisfactionAsyncThunk = asyncThunk(
  "satisfaction/survey/update",
  api.satisfaction.updateSatisfaction
);
