import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { GetInternalPageResponse } from "core/api/wp/api.wp.home.types";
import { SiteResponse } from "core/api/wp/api.wp.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import {
  GetSitePageAsyncThunkRequest,
  getSitePagesAsyncThunk,
} from "./pagesActions";

interface IPagesState {
  pages: {
    loading: LoadingType;
    data: {
      [k: string]: {
        [k: string]: {
          data?: GetInternalPageResponse;
        };
      };
    };
  };
}

const SitePagesSlice = createSlice<IPagesState, SliceCaseReducers<IPagesState>>(
  {
    name: "pages",
    initialState: {
      pages: {
        loading: "idle",
        data: {},
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getSitePagesAsyncThunk.pending.toString(), (state) => {
        state.pages.loading = "loading";
      });

      builder.addCase(
        getSitePagesAsyncThunk.fulfilled.toString(),
        (
          state,
          action: ActionWithArg<
            SiteResponse<GetInternalPageResponse>,
            GetSitePageAsyncThunkRequest
          >
        ) => {
          state.pages.loading = "ok";

          const slug = action.meta.arg?.slug || "";
          const lang = action.meta.arg?.lang || "pt";

          state.pages.data![slug] = { ...state.pages.data[slug] };

          state.pages.data[slug]![lang] = action.payload;
        }
      );

      builder.addCase(getSitePagesAsyncThunk.rejected.toString(), (state) => {
        state.pages.loading = "error";
      });
    },
  }
);

export default SitePagesSlice;
