import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import Feedback from "core/middlewares/Feedback";
import Menu from "core/middlewares/Menu";
import Unauthorized from "core/middlewares/Unauthorized";
import CompanySlice from "./slices/company/companySlice";
import ContactSlice from "./slices/contact/contactSlice";
import FeedbackSlice from "./slices/feedback/feedbackSlice";
import HelpersSlice from "./slices/helpers/helpersSlice";
import ProjectSlice from "./slices/project/projectSlice";
import SatisfactionSlice from "./slices/satisfaction/satisfactionSlice";
import siteReducer from "./slices/site";
import UserSlice from "./slices/user/userSlice";

const store = configureStore({
  reducer: combineReducers({
    company: CompanySlice.reducer,
    contact: ContactSlice.reducer,
    feedback: FeedbackSlice.reducer,
    helpers: HelpersSlice.reducer,
    user: UserSlice.reducer,
    satisfaction: SatisfactionSlice.reducer,
    project: ProjectSlice.reducer,

    // Site
    site: siteReducer,
  }),
  middleware: getDefaultMiddleware().concat(Unauthorized, Feedback, Menu),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
