import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  ListCity,
  ListCNAEByParentIDRequest,
  ListCNAEByParentIDResponse,
  ListCNAEDivision,
  ListCompanyClassification,
  ListCountry,
  ListNCMResponse,
  ListProduction,
  ListProjetoTiposResponse,
  ListRelationshipResponse,
  ListStatusProgressoResponse,
  ListUFResponse,
} from "core/api/api.helpers.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { orderBy } from "lodash";
import {
  listCityAsyncThunk,
  listCNAEByParentIDAsyncThunk,
  listCNAEDivisionAsyncThunk,
  listCompanyClassificationAsyncThunk,
  listCountryAsyncThunk,
  listGalpaoCondicaoAsyncThunk,
  listImovelAsyncThunk,
  listNCMAsyncThunk,
  listPortoAsyncThunk,
  listPrevisaoInstalacaoAsyncThunk,
  listProductionAsyncThunk,
  listProjetoTiposAsyncThunk,
  listRelationshipAsyncThunk,
  listRodoviaAsyncThunk,
  listStatusProgressoAsyncThunk,
  listTerrenoCondicaoAsyncThunk,
  listUFAsyncThunk,
} from "./helpersAction";

type Map<T> = { [index: string]: T };
interface IHelpers {
  uf: {
    loading: LoadingType;
    data: ListUFResponse;
  };
  production: {
    loading: LoadingType;
    data: ListProduction;
  };
  country: {
    loading: LoadingType;
    data: ListCountry;
  };
  city: {
    loading: LoadingType;
    data: ListCity;
  };
  companyClassification: {
    loading: LoadingType;
    data: ListCompanyClassification;
  };
  CNAEDivision: {
    loading: LoadingType;
    data: ListCNAEDivision;
  };
  CNAEByParentID: {
    loading: LoadingType;
    data: Map<ListCNAEByParentIDResponse>;
  };
  relationship: {
    loading: LoadingType;
    data: ListRelationshipResponse;
  };
  projetoTipos: {
    loading: LoadingType;
    data: ListProjetoTiposResponse;
  };
  statusProgresso: {
    loading: LoadingType;
    data: ListStatusProgressoResponse;
  };
  ncm: {
    loading: LoadingType;
    data: ListNCMResponse;
  };
  imovel: {
    loading: LoadingType;
    data: string[];
  };
  terrenoCondicao: {
    loading: LoadingType;
    data: string[];
  };
  galpaoCondicao: {
    loading: LoadingType;
    data: string[];
  };
  previsaoInstalacao: {
    loading: LoadingType;
    data: string[];
  };
  porto: {
    loading: LoadingType;
    data: string[];
  };
  rodovia: {
    loading: LoadingType;
    data: string[];
  };
}

const HelpersSlice = createSlice<IHelpers, SliceCaseReducers<IHelpers>>({
  name: "helpers",
  initialState: {
    uf: {
      loading: "idle",
      data: [],
    },
    production: {
      loading: "idle",
      data: [],
    },
    country: {
      loading: "idle",
      data: [],
    },
    city: {
      loading: "idle",
      data: [],
    },
    companyClassification: {
      loading: "idle",
      data: [],
    },
    CNAEDivision: {
      loading: "idle",
      data: [],
    },
    CNAEByParentID: {
      loading: "idle",
      data: {},
    },
    relationship: {
      loading: "idle",
      data: [],
    },
    projetoTipos: {
      loading: "idle",
      data: [],
    },
    statusProgresso: {
      loading: "idle",
      data: [],
    },
    ncm: {
      loading: "idle",
      data: [],
    },
    imovel: {
      loading: "idle",
      data: [],
    },
    terrenoCondicao: {
      loading: "idle",
      data: [],
    },
    galpaoCondicao: {
      loading: "idle",
      data: [],
    },
    previsaoInstalacao: {
      loading: "idle",
      data: [],
    },
    porto: {
      loading: "idle",
      data: [],
    },
    rodovia: {
      loading: "idle",
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    //UF
    builder.addCase(listUFAsyncThunk.pending.toString(), (state) => {
      state.uf.loading = "loading";
    });

    builder.addCase(
      listUFAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListUFResponse>) => {
        return {
          ...state,
          uf: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listUFAsyncThunk.rejected.toString(), (state) => {
      state.uf.loading = "error";
    });

    //PRODUCTION
    builder.addCase(listProductionAsyncThunk.pending.toString(), (state) => {
      state.production.loading = "loading";
    });

    builder.addCase(
      listProductionAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListProduction>) => {
        return {
          ...state,
          production: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listProductionAsyncThunk.rejected.toString(), (state) => {
      state.production.loading = "error";
    });

    //COUNTRY
    builder.addCase(listCountryAsyncThunk.pending.toString(), (state) => {
      state.country.loading = "loading";
    });

    builder.addCase(
      listCountryAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListCountry>) => {
        return {
          ...state,
          country: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listCountryAsyncThunk.rejected.toString(), (state) => {
      state.country.loading = "error";
    });

    //CITY
    builder.addCase(listCityAsyncThunk.pending.toString(), (state) => {
      state.city.loading = "loading";
    });

    builder.addCase(
      listCityAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListCity>) => {
        return {
          ...state,
          city: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listCityAsyncThunk.rejected.toString(), (state) => {
      state.city.loading = "error";
    });

    //CLASSIFICATION
    builder.addCase(
      listCompanyClassificationAsyncThunk.pending.toString(),
      (state) => {
        state.companyClassification.loading = "loading";
      }
    );

    builder.addCase(
      listCompanyClassificationAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListCompanyClassification>) => {
        return {
          ...state,
          companyClassification: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(
      listCompanyClassificationAsyncThunk.rejected.toString(),
      (state) => {
        state.companyClassification.loading = "error";
      }
    );

    //CNAE Division
    builder.addCase(listCNAEDivisionAsyncThunk.pending.toString(), (state) => {
      state.CNAEDivision.loading = "loading";
    });

    builder.addCase(
      listCNAEDivisionAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListCNAEDivision>) => {
        return {
          ...state,
          CNAEDivision: {
            loading: "ok",
            data: action.payload,
          },
        };
      }
    );

    builder.addCase(listCNAEDivisionAsyncThunk.rejected.toString(), (state) => {
      state.CNAEDivision.loading = "error";
    });

    //CNAE Group
    builder.addCase(
      listCNAEByParentIDAsyncThunk.pending.toString(),
      (state) => {
        state.CNAEByParentID.loading = "loading";
      }
    );

    builder.addCase(
      listCNAEByParentIDAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListCNAEByParentIDResponse,
          ListCNAEByParentIDRequest
        >
      ) => {
        const ids = action.meta.arg?.ids || [];
        const keys = Object.keys(state.CNAEByParentID.data);

        const newState = ids.reduce((acc, curr) => {
          if (keys.includes(curr)) return acc;
          return {
            ...acc,
            [curr]: action.payload,
          };
        }, {} as Map<ListCNAEByParentIDResponse>);

        return {
          ...state,
          CNAEByParentID: {
            loading: "ok",
            data: {
              ...state.CNAEByParentID.data,
              ...newState,
            },
          },
        };
      }
    );

    builder.addCase(
      listCNAEByParentIDAsyncThunk.rejected.toString(),
      (state) => {
        state.CNAEByParentID.loading = "error";
      }
    );

    // Relationship
    builder.addCase(listRelationshipAsyncThunk.pending.toString(), (state) => {
      state.relationship.loading = "loading";
    });

    builder.addCase(
      listRelationshipAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListRelationshipResponse>) => {
        state.relationship.loading = "ok";
        state.relationship.data = action.payload;
      }
    );

    builder.addCase(listRelationshipAsyncThunk.rejected.toString(), (state) => {
      state.relationship.loading = "error";
    });

    // Tipos de projetos
    builder.addCase(listProjetoTiposAsyncThunk.pending.toString(), (state) => {
      state.projetoTipos.loading = "loading";
    });

    builder.addCase(
      listProjetoTiposAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListProjetoTiposResponse>) => {
        state.projetoTipos.loading = "ok";
        state.projetoTipos.data = action.payload;
      }
    );

    builder.addCase(listProjetoTiposAsyncThunk.rejected.toString(), (state) => {
      state.projetoTipos.loading = "error";
    });

    // Status progresso
    builder.addCase(
      listStatusProgressoAsyncThunk.pending.toString(),
      (state) => {
        state.statusProgresso.loading = "loading";
      }
    );

    builder.addCase(
      listStatusProgressoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListProjetoTiposResponse>) => {
        state.statusProgresso.loading = "ok";
        state.statusProgresso.data = action.payload;
      }
    );

    builder.addCase(
      listStatusProgressoAsyncThunk.rejected.toString(),
      (state) => {
        state.statusProgresso.loading = "error";
      }
    );

    // List NCM
    builder.addCase(listNCMAsyncThunk.pending.toString(), (state) => {
      state.ncm.loading = "loading";
    });

    builder.addCase(
      listNCMAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListNCMResponse>) => {
        state.ncm.loading = "ok";
        state.ncm.data = Array.isArray(action.payload)
          ? orderBy(action.payload, ["descricao"])
          : [];
      }
    );

    builder.addCase(listNCMAsyncThunk.rejected.toString(), (state) => {
      state.ncm.loading = "error";
    });

    // List Imovel
    builder.addCase(
      listImovelAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.imovel.loading = "ok";
        state.imovel.data = Array.isArray(action.payload) ? action.payload : [];
      }
    );

    builder.addCase(listImovelAsyncThunk.pending.toString(), (state) => {
      state.imovel.loading = "loading";
    });

    builder.addCase(listImovelAsyncThunk.rejected.toString(), (state) => {
      state.imovel.loading = "error";
    });

    // List TerrenoCondicao
    builder.addCase(
      listTerrenoCondicaoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.terrenoCondicao.loading = "ok";
        state.terrenoCondicao.data = Array.isArray(action.payload)
          ? action.payload
          : [];
      }
    );

    builder.addCase(
      listTerrenoCondicaoAsyncThunk.pending.toString(),
      (state) => {
        state.terrenoCondicao.loading = "loading";
      }
    );

    builder.addCase(
      listTerrenoCondicaoAsyncThunk.rejected.toString(),
      (state) => {
        state.terrenoCondicao.loading = "error";
      }
    );

    // List TerrenoCondicao
    builder.addCase(
      listGalpaoCondicaoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.galpaoCondicao.loading = "ok";
        state.galpaoCondicao.data = Array.isArray(action.payload)
          ? action.payload
          : [];
      }
    );

    builder.addCase(
      listGalpaoCondicaoAsyncThunk.pending.toString(),
      (state) => {
        state.galpaoCondicao.loading = "loading";
      }
    );

    builder.addCase(
      listGalpaoCondicaoAsyncThunk.rejected.toString(),
      (state) => {
        state.galpaoCondicao.loading = "error";
      }
    );

    // List previsaoInstalacao
    builder.addCase(
      listPrevisaoInstalacaoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.previsaoInstalacao.loading = "ok";
        state.previsaoInstalacao.data = Array.isArray(action.payload)
          ? action.payload
          : [];
      }
    );

    builder.addCase(
      listPrevisaoInstalacaoAsyncThunk.pending.toString(),
      (state) => {
        state.previsaoInstalacao.loading = "loading";
      }
    );

    builder.addCase(
      listPrevisaoInstalacaoAsyncThunk.rejected.toString(),
      (state) => {
        state.previsaoInstalacao.loading = "error";
      }
    );

    // List Porto
    builder.addCase(
      listPortoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.porto.loading = "ok";
        state.porto.data = Array.isArray(action.payload) ? action.payload : [];
      }
    );

    builder.addCase(listPortoAsyncThunk.pending.toString(), (state) => {
      state.porto.loading = "loading";
    });

    builder.addCase(listPortoAsyncThunk.rejected.toString(), (state) => {
      state.porto.loading = "error";
    });

    // List Rodovia
    builder.addCase(
      listRodoviaAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<string[]>) => {
        state.rodovia.loading = "ok";
        state.rodovia.data = Array.isArray(action.payload)
          ? action.payload
          : [];
      }
    );

    builder.addCase(listRodoviaAsyncThunk.pending.toString(), (state) => {
      state.rodovia.loading = "loading";
    });

    builder.addCase(listRodoviaAsyncThunk.rejected.toString(), (state) => {
      state.rodovia.loading = "error";
    });
  },
});

export default HelpersSlice;
