import QueryString from "qs";
import { AUTH_REQUEST_DATA } from "utils/constants";
import APIbase from "./api.baseAPI";

interface AuthRequest {
  username: string;
  password: string;
}

interface AuthResponse {
  access_token: string;
  expires_in: number;
}

const auth = ({ username, password }: AuthRequest) => {
  const credentials = { username, password };

  const formData = QueryString.stringify({
    ...credentials,
    ...AUTH_REQUEST_DATA,
  });

  return APIbase.post<AuthResponse>("/server/api/oauth/token", formData);
};

interface PasswordRecoveryRequest {
  emailAddress: string;
}

const passwordRecovery = ({ emailAddress }: PasswordRecoveryRequest) =>
  APIbase.post<unknown>("/server/api/oauth/reset/request", { emailAddress });

interface ActivateUserRequest {
  email: string;
  token: string;
  password: string;
}

const activateUser = ({ email, password, token }: ActivateUserRequest) =>
  APIbase.post<unknown>("/server/api/user/ativar", { email, password, token });

const apiAuthentication = { auth, passwordRecovery, activateUser };

export default apiAuthentication;
