import { PayloadAction } from "@reduxjs/toolkit";
import { FeedbackData, FeedbackType } from "core/providers/FeedbackProvider";
import { feedbackAction } from "core/store/slices/feedback/feedbackAction";
import { ThunkOptions, RejectWithValue } from "core/utils/asyncThunk";
import { Dispatch } from "react";
import { AnyAction, MiddlewareAPI } from "redux";

const Feedback = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
  action: PayloadAction<
    FeedbackData | RejectWithValue,
    string,
    { arg: ThunkOptions }
  >
) => {
  if (
    action.type.endsWith("rejected") &&
    !store.getState()?.feedback?.data?.message &&
    !action.meta?.arg?.thunkOptions?.ignoreFeedbackGlobal
  ) {
    const message = "Ops! Tivemos um erro inesperado."; //TODO(Jeconias): Translate it.

    store.dispatch(feedbackAction({ type: FeedbackType.WARNING, message }));
  }

  return next(action);
};

export default Feedback;
