import QueryString from "qs";
import APIbase from "./api.baseAPI";
import {
  GetCompanyByIDRequest,
  GetCompanyByIDResponse,
  GetCompanyByParamsRequest,
  GetCompanyByParamsResponse,
  ListLinkedProcessRequest,
  ListLinkedProcessResponse,
  ListUnitByCompanyRequest,
  ListUnitByCompanyResponse,
  ListUnitRequest,
  ListUnitResponse,
  CreateAndPutUnitRequest,
  PutCompanyByIDRequest,
  UpdateInvestorResponsibleRequest,
  UpdateInvestorResponsibleResponse,
  CreateAndPutUnitResponse,
  DeleteUnitRequest,
  FindUnitRequest,
  ListEmpresaAtividadesRequest,
  ListEmpresaAtividadesResponse,
  ListLinkedProcessTemplateResponse,
  ListProjectAtividadesByCompanyRequest,
  ListProjectAtividadesByCompanyResponse,
} from "./api.company.types";

const getCompanyByID = ({ id }: GetCompanyByIDRequest) =>
  APIbase.get<GetCompanyByIDResponse>(`/server/api/empresa/${id}`);

const putCompanyByID = (data: PutCompanyByIDRequest) =>
  APIbase.put(`/server/api/empresa/${data.id}`, data);

const getCompanyByParams = (data: GetCompanyByParamsRequest) =>
  APIbase.get<GetCompanyByParamsResponse[]>(
    `/server/api/empresa${QueryString.stringify(data, {
      addQueryPrefix: true,
    })}`
  );

const listUnitByCompany = ({ companyID, ...data }: ListUnitByCompanyRequest) =>
  APIbase.get<ListUnitByCompanyResponse[]>(
    `/server/api/empresa/${companyID}/empresaUnidadeDTOAtivas${QueryString.stringify(
      data,
      {
        addQueryPrefix: true,
      }
    )}`
  );

const updateInvestorResponsible = (data: UpdateInvestorResponsibleRequest) =>
  APIbase.post<UpdateInvestorResponsibleResponse>(
    `/server/api/empresa/updateInvestorResponsible`,
    data
  );

const listLinkedProcessTemplate = ({ companyID }: ListLinkedProcessRequest) =>
  APIbase.get<ListLinkedProcessTemplateResponse>(
    `/server/api/empresa/${companyID}/processosVinculados/template`
  );

const listLinkedProcess = ({ companyID }: ListLinkedProcessRequest) =>
  APIbase.get<ListLinkedProcessResponse>(
    `/server/api/empresa/${companyID}/processosVinculados`
  );

const findUnit = ({ companyID, nomeUnidade }: FindUnitRequest) =>
  APIbase.get<number>(
    `/server/api/empresa/${companyID}/unidade/getUnidadeComNome${QueryString.stringify(
      { nomeUnidade },
      {
        addQueryPrefix: true,
      }
    )}`
  );

const listUnit = ({ companyID }: ListUnitRequest) =>
  APIbase.get<ListUnitResponse>(`/server/api/empresa/${companyID}/unidade`);

const createAndPutUnit = (data: CreateAndPutUnitRequest) =>
  APIbase.post<CreateAndPutUnitResponse>(
    `/server/api/empresa/${data.empresa.id}/unidade`,
    data
  );

const deleteUnit = ({ companyID, units }: DeleteUnitRequest) =>
  APIbase.post(`/server/api/empresa/${companyID}/unidade/removeUnidade`, units);

const listEmpresaAtividades = ({ empresaId }: ListEmpresaAtividadesRequest) =>
  APIbase.get<ListEmpresaAtividadesResponse>(
    `/server/api/pendencia/empresa/${empresaId}`
  );

const listProjectAtividadesByCompany = ({
  empresaId,
}: ListProjectAtividadesByCompanyRequest) =>
  APIbase.get<ListProjectAtividadesByCompanyResponse>(
    `/server/api/instrumento/empresa/${empresaId}/atividadesInstrumentos`
  );

const apiCompany = {
  getCompanyByID,
  getCompanyByParams,
  putCompanyByID,
  findUnit,
  listUnitByCompany,
  listLinkedProcessTemplate,
  listLinkedProcess,
  listUnit,
  createAndPutUnit,
  deleteUnit,
  updateInvestorResponsible,
  listEmpresaAtividades,
  listProjectAtividadesByCompany,
};

export default apiCompany;
