import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { GetHomeResponse } from "core/api/wp/api.wp.home.types";
import { SiteResponse } from "core/api/wp/api.wp.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { getSiteHomeAsyncThunk } from "./homeActions";

interface IHomeState {
  home: {
    loading: LoadingType;
    data: {
      [k: string]: GetHomeResponse;
    };
  };
}

const SiteHomeSlice = createSlice<IHomeState, SliceCaseReducers<IHomeState>>({
  name: "home",
  initialState: {
    home: {
      loading: "idle",
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSiteHomeAsyncThunk.pending.toString(), (state) => {
      state.home.loading = "loading";
    });

    builder.addCase(
      getSiteHomeAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<SiteResponse<GetHomeResponse>>) => {
        state.home.loading = "ok";

        const { data } = action.payload;

        state.home.data![data.__response_lang || "pt"] = data;
      }
    );

    builder.addCase(getSiteHomeAsyncThunk.rejected.toString(), (state) => {
      state.home.loading = "error";
    });
  },
});

export default SiteHomeSlice;
