import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  DeleteUnitRequest,
  GetCompanyByIDResponse,
  ListLinkedProcessRequest,
  ListLinkedProcessResponse,
  ListUnitRequest,
  ListUnitResponse,
  CreateAndPutUnitRequest,
  CreateAndPutUnitResponse,
  ListEmpresaAtividadesResponse,
  ListEmpresaAtividadesRequest,
  ListLinkedProcessTemplateResponse,
  ListProjectAtividadesByCompanyResponse,
  ListProjectAtividadesByCompanyRequest,
} from "core/api/api.company.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { cloneDeep } from "lodash";
import { logoutAction } from "../auth/authAction";
import {
  deleteUnitAsyncThunk,
  getCompanyByIDAsyncThunk,
  listLinkedProcessAsyncThunk,
  listUnitAsyncThunk,
  createAndPutUnitAsyncThunk,
  listEmpresaAtividadesAsyncThunk,
  listLinkedProcessTemplateAsyncThunk,
  listProjectAtividadesByCompanyAsyncThunk,
} from "./companyAction";

type Map<T> = { [index: string]: T | undefined };

interface IHelpers {
  companies: {
    loading: LoadingType;
    data: Map<GetCompanyByIDResponse>;
  };
  linkedProcessByCompany: {
    loading: LoadingType;
    data: Map<ListLinkedProcessResponse>;
  };
  linkedProcessTemplateByCompany: {
    loading: LoadingType;
    data: Map<ListLinkedProcessTemplateResponse>;
  };
  units: {
    loading: LoadingType;
    data: Map<ListUnitResponse>;
  };
  atividades: Map<{
    loading: LoadingType;
    atividades: ListEmpresaAtividadesResponse;
  }>;
  projetoAtividades: {
    loading: LoadingType;
    data?: ListProjectAtividadesByCompanyResponse;
  };
}

const STATE: { loading: LoadingType; data: Map<any> } = {
  loading: "idle",
  data: {},
};

const CompanySlice = createSlice<IHelpers, SliceCaseReducers<IHelpers>>({
  name: "company",
  initialState: {
    companies: cloneDeep(STATE),
    linkedProcessByCompany: cloneDeep(STATE),
    linkedProcessTemplateByCompany: cloneDeep(STATE),
    units: cloneDeep(STATE),
    atividades: {},
    projetoAtividades: {
      loading: "idle",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // GET
    builder.addCase(getCompanyByIDAsyncThunk.pending.toString(), (state) => {
      state.companies.loading = "loading";
    });

    builder.addCase(
      getCompanyByIDAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<GetCompanyByIDResponse>) => {
        return {
          ...state,
          companies: {
            ...state.companies,
            loading: "ok",
            data: {
              ...state.companies.data,
              [action.payload.id]: action.payload,
            },
          },
        };
      }
    );

    builder.addCase(getCompanyByIDAsyncThunk.rejected.toString(), (state) => {
      state.companies.loading = "error";
    });

    // List linkedProcessByCompany
    builder.addCase(listLinkedProcessAsyncThunk.pending.toString(), (state) => {
      state.linkedProcessByCompany.loading = "loading";
    });

    builder.addCase(
      listLinkedProcessAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListLinkedProcessResponse,
          ListLinkedProcessRequest
        >
      ) => {
        state.linkedProcessByCompany.loading = "ok";
        if (!action.meta.arg?.companyID) return;

        state.linkedProcessByCompany.data[action.meta.arg?.companyID] =
          action.payload;
      }
    );

    builder.addCase(
      listLinkedProcessAsyncThunk.rejected.toString(),
      (state) => {
        state.linkedProcessByCompany.loading = "error";
      }
    );

    // List linkedProcessByCompanyTemplate
    builder.addCase(
      listLinkedProcessTemplateAsyncThunk.pending.toString(),
      (state) => {
        state.linkedProcessTemplateByCompany.loading = "loading";
      }
    );

    builder.addCase(
      listLinkedProcessTemplateAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListLinkedProcessTemplateResponse,
          ListLinkedProcessRequest
        >
      ) => {
        state.linkedProcessTemplateByCompany.loading = "ok";
        if (!action.meta.arg?.companyID) return;

        state.linkedProcessTemplateByCompany.data[action.meta.arg?.companyID] =
          action.payload;
      }
    );

    builder.addCase(
      listLinkedProcessTemplateAsyncThunk.rejected.toString(),
      (state) => {
        state.linkedProcessTemplateByCompany.loading = "error";
      }
    );

    // List Unit
    builder.addCase(listUnitAsyncThunk.pending.toString(), (state) => {
      state.units.loading = "loading";
    });

    builder.addCase(
      listUnitAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<ListUnitResponse, ListUnitRequest>) => {
        state.units.loading = "ok";
        if (!action.meta.arg?.companyID) return;

        state.units.data[action.meta.arg?.companyID] = action.payload;
      }
    );

    builder.addCase(listUnitAsyncThunk.rejected.toString(), (state) => {
      state.units.loading = "error";
    });

    // Put Unit
    builder.addCase(
      createAndPutUnitAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<CreateAndPutUnitResponse, CreateAndPutUnitRequest>
      ) => {
        state.units.loading = "ok";

        if (!action.meta.arg?.empresa?.id) return;

        const units = state.units.data[action.meta.arg.empresa.id];
        const hasUnit = units?.find((u) => u.id === action.meta.arg?.id);
        if (hasUnit) {
          state.units.data[action.meta.arg.empresa.id] = [
            ...(state.units.data[action.meta.arg.empresa.id] || []).filter(
              (u) => u.id !== action.meta.arg?.id
            ),
            action.payload,
          ];
        } else {
          state.units.data[action.meta.arg.empresa.id] = [
            ...(state.units.data[action.meta.arg.empresa.id] || []),
            action.payload,
          ];
        }
      }
    );

    // Delete Unit
    builder.addCase(
      deleteUnitAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<number, DeleteUnitRequest>) => {
        if (!action.meta.arg?.companyID) return;

        state.units.data[action.meta.arg.companyID] = state.units.data[
          action.meta.arg.companyID
        ]?.filter((u) => !action.meta.arg?.units?.includes(u.id));
      }
    );

    // List atividades
    builder.addCase(
      listEmpresaAtividadesAsyncThunk.pending.toString(),
      (
        state,
        action: ActionWithArg<
          ListEmpresaAtividadesResponse,
          ListEmpresaAtividadesRequest
        >
      ) => {
        if (!action.meta.arg?.empresaId) return;
        state.atividades[action.meta.arg!.empresaId ?? ""] = {
          loading: "loading",
          atividades: [],
        };
      }
    );

    builder.addCase(
      listEmpresaAtividadesAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListEmpresaAtividadesResponse,
          ListEmpresaAtividadesRequest
        >
      ) => {
        if (!action.meta.arg?.empresaId) return;

        state.atividades[action.meta.arg!.empresaId ?? ""]!.loading = "ok";
        state.atividades[action.meta.arg!.empresaId ?? ""]!.atividades =
          action.payload;
      }
    );

    builder.addCase(
      listEmpresaAtividadesAsyncThunk.rejected.toString(),
      (
        state,
        action: ActionWithArg<
          ListEmpresaAtividadesResponse,
          ListEmpresaAtividadesRequest
        >
      ) => {
        if (!action.meta.arg?.empresaId) return;
        state.atividades[action.meta.arg!.empresaId ?? ""] = {
          loading: "error",
          atividades: [],
        };
      }
    );

    // List projeto atividades
    builder.addCase(
      listProjectAtividadesByCompanyAsyncThunk.pending.toString(),
      (state) => {
        state.projetoAtividades = {
          loading: "loading",
          data: undefined,
        };
      }
    );

    builder.addCase(
      listProjectAtividadesByCompanyAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListProjectAtividadesByCompanyResponse,
          ListProjectAtividadesByCompanyRequest
        >
      ) => {
        state.projetoAtividades.loading = "ok";
        state.projetoAtividades.data = action.payload;
      }
    );

    builder.addCase(
      listProjectAtividadesByCompanyAsyncThunk.rejected.toString(),
      (state) => {
        state.projetoAtividades = {
          loading: "error",
          data: undefined,
        };
      }
    );

    //Reset
    builder.addCase(logoutAction.toString(), (state) => {
      state.companies = cloneDeep(STATE);
      state.linkedProcessByCompany = cloneDeep(STATE);
      state.units = cloneDeep(STATE);
      state.atividades = {};
      state.projetoAtividades = {
        loading: "idle",
        data: undefined,
      };
    });
  },
});

export default CompanySlice;
