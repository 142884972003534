import api from "core/api";
import asyncThunk from "core/utils/asyncThunk";

export const getCompanyByIDAsyncThunk = asyncThunk(
  "company/byId/get",
  api.company.getCompanyByID,
  {
    condition: (args, state) =>
      !state.getState().company.companies.data[args?.id] ||
      !!args?.thunkOptions?.isForce,
  }
);

export const putCompanyByIDAsyncThunk = asyncThunk(
  "company/byId/put",
  api.company.putCompanyByID
);

export const findUnitAsyncThunk = asyncThunk(
  "company/unit/find",
  api.company.findUnit
);

export const listUnitByCompanyAsyncThunk = asyncThunk(
  "company/unit/list",
  api.company.listUnitByCompany
);

export const listLinkedProcessAsyncThunk = asyncThunk(
  "company/linkedProcess/list",
  api.company.listLinkedProcess,
  {
    condition: (args, store) =>
      !store.getState().company.linkedProcessByCompany.data[
        args?.companyID || ""
      ]?.length,
  }
);

export const listLinkedProcessTemplateAsyncThunk = asyncThunk(
  "company/linkedProcessTemplate/list",
  api.company.listLinkedProcessTemplate,
  {
    condition: (args, store) =>
      !store.getState().company.linkedProcessTemplateByCompany.data[
        args?.companyID || ""
      ]?.length,
  }
);

export const listUnitAsyncThunk = asyncThunk(
  "company/unit/list",
  api.company.listUnit,
  {
    condition: (args, store) =>
      !store.getState().company.units.data[args?.companyID || ""]?.length,
  }
);

export const createAndPutUnitAsyncThunk = asyncThunk(
  "company/createAndPut/put",
  api.company.createAndPutUnit
);

export const deleteUnitAsyncThunk = asyncThunk(
  "company/unit/delete",
  api.company.deleteUnit
);

export const updateInvestorResponsibleAsyncThunk = asyncThunk(
  "company/contanct/responsible/update",
  api.company.updateInvestorResponsible
);

export const listEmpresaAtividadesAsyncThunk = asyncThunk(
  "company/atividade/list",
  api.company.listEmpresaAtividades,
  {
    condition: (args, store) =>
      !store.getState().company.atividades[args?.empresaId || ""] ||
      store.getState().company.atividades[args?.empresaId ?? ""]?.loading ===
        "error" ||
      store.getState().company.atividades[args?.empresaId ?? ""]?.loading ===
        "idle",
  }
);

export const listProjectAtividadesByCompanyAsyncThunk = asyncThunk(
  "project/atividade/byCompany/list",
  api.company.listProjectAtividadesByCompany
);
