import api from "core/api";
import { GetPageRequest } from "core/api/wp/api.wp.pages";
import { SiteLang } from "core/api/wp/api.wp.types";
import asyncThunk from "core/utils/asyncThunk";

export interface GetSitePageAsyncThunkRequest extends GetPageRequest {
  lang?: SiteLang;
}

export const getSitePagesAsyncThunk = asyncThunk<GetSitePageAsyncThunkRequest>(
  "site/pages/get",
  api.wp.pages.getInternalPage,
  {
    condition: (props, state) =>
      !(
        state.getState().site.pages.pages.data[props?.slug || ""] &&
        state.getState().site.pages.pages.data[props?.slug || ""][
          props?.lang || "pt"
        ]
      ),
  }
);
