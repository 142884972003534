import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import {
  Ambience,
  Finance,
  GetAcompanhamentoAnualRequest,
  GetAcompanhamentoAnualResponse,
  GetAmbienceRequest,
  GetFinanceRequest,
  GetFinanceResponse,
  GetLocationRequest,
  GetLocationResponse,
  GetPleitoRequest,
  GetPleitoResponse,
  GetProjectRequest,
  GetProjectResponse,
  GetProjectXResponse,
  GetScheduleRequest,
  GetScheduleResponse,
  Infrastructure,
  ListAmbienceResponse,
  ListInfrastructureRequest,
  ListInfrastructureResponse,
  ListProductRequest,
  ListProductResponse,
  ListProjectRequest,
  ListProjectResponse,
  Location,
  Pleito,
  Project,
} from "core/api/api.project.types";
import { ActionWithArg, LoadingType } from "core/utils/types";
import { cloneDeep } from "lodash";
import { logoutAction } from "../auth/authAction";
import {
  getAcompanhamentoAnualAsyncThunk,
  getAmbienceAsyncThunk,
  getFinanceAsyncThunk,
  getInfrastructureAsyncThunk,
  getLocationAsyncThunk,
  getPleitoAsyncThunk,
  getProjectAsyncThunk,
  getProjectXAsyncThunk,
  getScheduleAsyncThunk,
  listProductAsyncThunk,
  listProjectAsyncThunk,
} from "./projectAction";

type Map<T> = { [index: string]: T };

interface IHelpers {
  projects: {
    loading: LoadingType;
    data: Map<GetProjectResponse | undefined>;
    dataX: Map<{
      loading: LoadingType;
      project?: GetProjectXResponse;
    }>;
  };

  list: {
    loading: LoadingType;
    projects: any[];
    count?: number;
  };

  acompanhamento: {
    loading: LoadingType;
    data: Map<GetAcompanhamentoAnualResponse>;
  };

  pleito: {
    loading: LoadingType;
    data: Map<Pleito | undefined>;
  };

  location: {
    loading: LoadingType;
    data: Map<Location | undefined>;
  };
  finance: {
    loading: LoadingType;
    data: Map<Finance | undefined>;
  };
  schedule: {
    loading: LoadingType;
    data: Map<GetScheduleResponse | undefined>;
  };
  products: {
    loading: LoadingType;
    data: Map<ListProductResponse | undefined>;
  };
  infrastructure: {
    loading: LoadingType;
    data: Map<Infrastructure | undefined>;
  };
  ambience: {
    loading: LoadingType;
    data: Map<Ambience | undefined>;
  };
}

const initialState: IHelpers = {
  projects: {
    loading: "idle",
    data: {},
    dataX: {},
  },
  list: {
    loading: "idle",
    projects: [],
  },
  acompanhamento: {
    loading: "idle",
    data: {},
  },
  pleito: {
    loading: "idle",
    data: {},
  },
  location: {
    loading: "idle",
    data: {},
  },
  finance: {
    loading: "idle",
    data: {},
  },
  schedule: {
    loading: "idle",
    data: {},
  },
  products: {
    loading: "idle",
    data: {},
  },
  infrastructure: {
    loading: "idle",
    data: {},
  },
  ambience: {
    loading: "idle",
    data: {},
  },
};

const ProjectSlice = createSlice<IHelpers, SliceCaseReducers<IHelpers>>({
  name: "project",
  initialState: initialState,
  reducers: {
    updateProjectXById: (
      state,
      action: PayloadAction<Partial<GetProjectXResponse>>
    ) => {
      if (!state.projects.dataX[action.payload.id!]) return;

      state.projects.dataX[action.payload.id!].project = {
        ...state.projects.dataX[action.payload.id!].project,
        ...(({
          ...action.payload,
          id: state.projects.dataX[action.payload.id!].project!.id,
        } || {}) as GetProjectXResponse),
      };
    },
  },
  extraReducers: (builder) => {
    // GET
    builder.addCase(getProjectAsyncThunk.pending.toString(), (state) => {
      state.projects.loading = "loading";
    });

    builder.addCase(
      getProjectAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<GetProjectResponse, GetProjectRequest>) => {
        state.projects.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        state.projects.data[action.meta.arg?.projectID] = action.payload;
      }
    );

    builder.addCase(getProjectAsyncThunk.rejected.toString(), (state) => {
      state.projects.loading = "error";
    });

    // GET X
    builder.addCase(
      getProjectXAsyncThunk.pending.toString(),
      (
        state,
        action: ActionWithArg<GetProjectXResponse, GetProjectRequest>
      ) => {
        if (!action.meta.arg?.projectID) return;
        state.projects.dataX[action.meta.arg?.projectID] = {
          loading: "loading",
        };
      }
    );

    builder.addCase(
      getProjectXAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<GetProjectXResponse, GetProjectRequest>
      ) => {
        if (!action.meta.arg?.projectID) return;

        state.projects.dataX[action.meta.arg?.projectID] = {
          loading: "ok",
          project: action.payload,
        };
      }
    );

    builder.addCase(
      getProjectXAsyncThunk.rejected.toString(),
      (
        state,
        action: ActionWithArg<GetProjectXResponse, GetProjectRequest>
      ) => {
        if (!action.meta.arg?.projectID) return;
        state.projects.dataX[action.meta.arg?.projectID] = {
          loading: "error",
        };
      }
    );

    // LIST
    builder.addCase(
      listProjectAsyncThunk.pending.toString(),
      (
        state,
        action: ActionWithArg<ListProjectResponse, ListProjectRequest>
      ) => {
        if (action.meta.arg?.countResults) {
          return;
        }
        state.list.loading = "loading";
      }
    );

    builder.addCase(
      listProjectAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<ListProjectResponse, ListProjectRequest>
      ) => {
        if (action.meta.arg?.countResults) {
          state.list.count = action.payload as number;
          return;
        }
        state.list.loading = "ok";
        state.list.projects = action.payload as Project[];
      }
    );

    builder.addCase(
      listProjectAsyncThunk.rejected.toString(),
      (
        state,
        action: ActionWithArg<ListProjectResponse, ListProjectRequest>
      ) => {
        if (action.meta.arg?.countResults) {
          return;
        }
        state.list.loading = "error";
      }
    );

    // Get Acompanhamento
    builder.addCase(
      getAcompanhamentoAnualAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          GetAcompanhamentoAnualResponse,
          GetAcompanhamentoAnualRequest
        >
      ) => {
        state.acompanhamento.loading = "ok";

        if (!action.meta.arg?.familiaId && !action.meta.arg?.projetoId) return;

        state.acompanhamento.data[
          `${action.meta.arg?.familiaId}-${action.meta.arg?.projetoId}`
        ] = action.payload;
      }
    );

    builder.addCase(
      getAcompanhamentoAnualAsyncThunk.pending.toString(),
      (state) => {
        state.acompanhamento.loading = "loading";
      }
    );

    builder.addCase(
      getAcompanhamentoAnualAsyncThunk.rejected.toString(),
      (state) => {
        state.acompanhamento.loading = "error";
      }
    );

    // Get Pleito
    builder.addCase(
      getPleitoAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<GetPleitoResponse, GetPleitoRequest>) => {
        state.pleito.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.pleito.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.pleito.data[
            action.meta.arg.projectID
          ] = action.payload as Pleito;
        }
      }
    );

    builder.addCase(getPleitoAsyncThunk.pending.toString(), (state) => {
      state.pleito.loading = "loading";
    });

    builder.addCase(getPleitoAsyncThunk.rejected.toString(), (state) => {
      state.pleito.loading = "error";
    });

    // Get Location
    builder.addCase(
      getLocationAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<GetLocationResponse, GetLocationRequest>
      ) => {
        state.location.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.location.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.location.data[
            action.meta.arg.projectID
          ] = action.payload as Location;
        }
      }
    );

    builder.addCase(getLocationAsyncThunk.pending.toString(), (state) => {
      state.location.loading = "loading";
    });

    builder.addCase(getLocationAsyncThunk.rejected.toString(), (state) => {
      state.location.loading = "error";
    });

    // Get Finance
    builder.addCase(
      getFinanceAsyncThunk.fulfilled.toString(),
      (state, action: ActionWithArg<GetFinanceResponse, GetFinanceRequest>) => {
        state.finance.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.finance.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.finance.data[
            action.meta.arg.projectID
          ] = action.payload as Finance;
        }
      }
    );

    builder.addCase(getFinanceAsyncThunk.pending.toString(), (state) => {
      state.finance.loading = "loading";
    });

    builder.addCase(getFinanceAsyncThunk.rejected.toString(), (state) => {
      state.finance.loading = "error";
    });

    // Get Schedule
    builder.addCase(
      getScheduleAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<GetScheduleResponse, GetScheduleRequest>
      ) => {
        state.schedule.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.schedule.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.schedule.data[
            action.meta.arg.projectID
          ] = action.payload as GetScheduleResponse;
        }
      }
    );

    builder.addCase(getScheduleAsyncThunk.pending.toString(), (state) => {
      state.schedule.loading = "loading";
    });

    builder.addCase(getScheduleAsyncThunk.rejected.toString(), (state) => {
      state.schedule.loading = "error";
    });

    // List Product
    builder.addCase(
      listProductAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<ListProductResponse, ListProductRequest>
      ) => {
        state.products.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.products.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.products.data[
            action.meta.arg.projectID
          ] = action.payload as ListProductResponse;
        }
      }
    );

    builder.addCase(listProductAsyncThunk.pending.toString(), (state) => {
      state.products.loading = "loading";
    });

    builder.addCase(listProductAsyncThunk.rejected.toString(), (state) => {
      state.products.loading = "error";
    });

    // Get Infrastructure
    builder.addCase(
      getInfrastructureAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<
          ListInfrastructureResponse,
          ListInfrastructureRequest
        >
      ) => {
        state.infrastructure.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.infrastructure.data[action.meta.arg.projectID] =
            action.payload[action.payload.length - 1];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.infrastructure.data[
            action.meta.arg.projectID
          ] = action.payload as Infrastructure;
        }
      }
    );

    builder.addCase(getInfrastructureAsyncThunk.pending.toString(), (state) => {
      state.infrastructure.loading = "loading";
    });

    builder.addCase(
      getInfrastructureAsyncThunk.rejected.toString(),
      (state) => {
        state.infrastructure.loading = "error";
      }
    );

    // Get Ambience
    builder.addCase(
      getAmbienceAsyncThunk.fulfilled.toString(),
      (
        state,
        action: ActionWithArg<ListAmbienceResponse, GetAmbienceRequest>
      ) => {
        state.ambience.loading = "ok";
        if (!action.meta.arg?.projectID) return;

        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.ambience.data[action.meta.arg.projectID] = action.payload[0];
        } else if (
          typeof action.payload === "object" &&
          !Array.isArray(action.payload)
        ) {
          state.ambience.data[
            action.meta.arg.projectID
          ] = action.payload as Ambience;
        }
      }
    );

    builder.addCase(getAmbienceAsyncThunk.pending.toString(), (state) => {
      state.ambience.loading = "loading";
    });

    builder.addCase(getAmbienceAsyncThunk.rejected.toString(), (state) => {
      state.ambience.loading = "error";
    });

    //Reset
    builder.addCase(logoutAction.toString(), (state) => {
      state.projects = cloneDeep(initialState.projects);
      state.list = cloneDeep(initialState.list);
      state.acompanhamento = cloneDeep(initialState.acompanhamento);
      state.pleito = cloneDeep(initialState.pleito);
      state.location = cloneDeep(initialState.location);
      state.finance = cloneDeep(initialState.finance);
      state.schedule = cloneDeep(initialState.schedule);
      state.products = cloneDeep(initialState.products);
      state.infrastructure = cloneDeep(initialState.infrastructure);
      state.ambience = cloneDeep(initialState.ambience);
    });
  },
});

export const { updateProjectXById } = ProjectSlice.actions;

export default ProjectSlice;
