import { FC } from "core/utils/types";
import { createContext, useCallback, useState } from "react";

interface ManagerActionsProps {
  menuIsOpen: boolean;
  menuToggle: (newState?: boolean | any) => void;
}

export const ManagerActionsContext = createContext<ManagerActionsProps>(
  {} as ManagerActionsProps
);

const ManagerActions: FC = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuToggle = useCallback(
    (newState) =>
      setMenuIsOpen((prev) =>
        typeof newState === "boolean" ? newState : !prev
      ),
    []
  );

  return (
    <ManagerActionsContext.Provider value={{ menuIsOpen, menuToggle }}>
      {children}
    </ManagerActionsContext.Provider>
  );
};

export default ManagerActions;
