import APIbaseWP from "./api.wp.baseAPI";
import { GetHomeResponse } from "./api.wp.home.types";
import { SiteResponse } from "./api.wp.types";

const getHome = () => APIbaseWP.get<SiteResponse<GetHomeResponse>>(`/v1/home`);

const apiHome = {
  getHome,
};

export default apiHome;
